import { Button, Divider, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { companyLogin } from "../../features/company/actions";
import { employeeLogin } from "../../features/employee/actions";
import { ILogin } from "../../interfaces";

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const companyState = useAppSelector((state) => state.company);
  const employeeState = useAppSelector((state) => state.employee);
  const [userType, setUserType] = useState<"company" | "employee">("employee");

  const isCompany = userType === "company";

  const typeLabel = isCompany ? "Байгууллага" : "Ажилтан";

  const toggleUserType = () => {
    setUserType(isCompany ? "employee" : "company");
  };

  const onSubmit = (dto: ILogin) => {
    if (isCompany) {
      dispatch(companyLogin(dto));
    } else {
      dispatch(employeeLogin(dto));
    }
  };

  useEffect(() => {
    if (companyState.error) message.error(companyState.error);
    if (employeeState.error) message.error(employeeState.error);
  }, [companyState.error, employeeState.error]);

  return (
    <div style={{ minWidth: 450 }}>
      <div style={{ flex: 1, alignItems: "center", display: "flex" }}>
        <h1 className="pt-4 pl-4 font-bold text-4xl">{typeLabel} нэвтрэх</h1>
      </div>
      <div style={{ alignSelf: "center", flex: 2, width: "100%" }}>
        <Form
          labelCol={{ span: 4 }}
          onFinish={onSubmit}
          autoComplete="off"
          disabled={companyState.loading || employeeState.loading}
        >
          <Form.Item
            label={<span>Имэйл</span>}
            name="email"
            rules={[{ message: "Имэйлээ оруулна уу" }]}
          >
            <Input
              type="email"
              onInput={(e: any) =>
                (e.target.value = e.target.value.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item
            label={<span>Нууц үг</span>}
            style={{ color: "white" }}
            name="password"
            rules={[{ message: "Нууц үгээ оруулна уу" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                loading={companyState.loading || employeeState.loading}
              >
                Нэвтрэх
              </Button>
              <a href={`/forgot_password?userType=${userType}`}>
                Нууц үг мартсан
              </a>
            </div>
          </Form.Item>

          <Divider plain>Эсвэл</Divider>

          <Form.Item className="w-full">
            <Button
              size="large"
              onClick={toggleUserType}
              className="rounded-xl w-full"
            >
              {isCompany ? "Ажилтнаар" : "Байгууллагаар"} нэвтрэх
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
