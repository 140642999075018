import { Button, Form, Input, message } from "antd";
import { useSearchParams } from "react-router-dom";
import { apiClient } from "../../api";

const ForgotPassword = () => {
  const [searchParams] = useSearchParams();

  const userType = searchParams.get("userType");
  const isCompany = userType === "company";
  const typeLabel = isCompany ? "Байгууллага" : "Ажилтан";

  const onSubmit = async (dto: { email: string }) => {
    try {
      const url = isCompany ? "/auth-company/forgot" : "/auth-employee/forgot";
      await apiClient.post(`${url}/${dto.email.toLowerCase()}`);
      message.success("Имэйл хаягаа шалгана уу");
    } catch (err: any) {
      message.error(err.response?.data?.message || err.message);
    }
  };

  return (
    <div style={{ minWidth: 450 }}>
      <div style={{ flex: 1, alignItems: "center", display: "flex" }}>
        <h1 className="pt-4 pl-4 font-bold text-4xl">
          {typeLabel} нууц үг сэргээх
        </h1>
      </div>
      <div style={{ alignSelf: "center", flex: 2, width: "100%" }}>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          onFinish={onSubmit}
          autoComplete="off"
        >
          <Form.Item
            label={<span>Имэйл</span>}
            name="email"
            rules={[{ message: "Имэйлээ оруулна уу" }]}
          >
            <Input
              type="email"
              onInput={(e: any) =>
                (e.target.value = e.target.value.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div style={{ marginBottom: "8px" }}>
              Бүртгэлтэй имэйл хаягаа оруулна уу
            </div>
            <Button type="primary" htmlType="submit">
              Сэргээх
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
